import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  hide = true;
  maxDate;
  public registerFG: FormGroup;


  constructor(private _formBuilder: FormBuilder, private authService: AuthService) { }

  ngOnInit() {

    this.maxDate = moment().subtract('18','years').format();

    this.registerFG = this._formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      phone_number: ['', Validators.required],
      country: ['MX', Validators.required],
      date_of_birth: [''],
      gender: ['Male', Validators.required],
    });
  }

  dateNow(date){
    console.log(date)
    this.registerFG.setValue({ ...this.registerFG.value, date_of_birth: date});
  }

  register() {

    let date = new Date(this.registerFG.value.date_of_birth);

    let user = {
      "first_name": (this.registerFG.value.first_name),
      "last_name": (this.registerFG.value.last_name),
      "email": (this.registerFG.value.email),
      "password": (this.registerFG.value.password),
      "phone": (this.registerFG.value.phone_number),
      "country": (this.registerFG.value.country),
      "birth_at": ( date.getTime() ),
      "gender": (this.registerFG.value.gender),
    }

    // let encryptUSer = { data: this.authService.encrypt(user,"public") }

    this.authService.register(user);

  }

}
