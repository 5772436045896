import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { BookinComponent } from './bookin/bookin.component';
import { ChatComponent } from './chat/chat.component';
import { SettingsComponent } from './settings/settings.component';
import { PanelComponent } from './panel/panel.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { AuthGuard } from './auth/auth.guard';
import { EditProfileComponent } from './settings/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './settings/change-password/change-password.component';
import { ContactComponent } from './settings/contact/contact.component';
import { FaqComponent } from './settings/faq/faq.component';
import { VideoChatComponent } from './bookin/video-chat/video-chat.component';
import { LandingComponent } from './landing/landing.component';
import { HorariosComponent } from './horarios/horarios.component';
import { RePasswordComponent } from './auth/re-password/re-password.component';
import { RecipeComponent} from './bookin/recipe/recipe.component';
import { FilesComponent } from './files/files.component';
import { NewReportComponent } from './new-report/new-report.component';
import { ViewReportComponent } from './view-report/view-report.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'recuperar', component: RePasswordComponent },

  {
    path: 'panel', component: PanelComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'horarios', component: HorariosComponent },
      { path: 'booking', component: BookinComponent },
      { path: 'video/:id', component: VideoChatComponent },
      { path: 'chat', component: ChatComponent },
      { path: 'settings', component: SettingsComponent,
        children: [
          { path: 'edit-profile', component: EditProfileComponent },
          { path: 'change-password', component: ChangePasswordComponent },
          { path: 'contact', component: ContactComponent },
          { path: 'faq', component: FaqComponent },
          { path: '**', redirectTo: 'edit-profile' }
        ]
      },
      { path:'recipes', component: RecipeComponent},
      { path:'recipes/:patient_id', component: RecipeComponent},
      { path:'files', component: FilesComponent},
      { path:'crear_reporte', component: NewReportComponent},
      { path:'ver_reporte', component: ViewReportComponent},
      { path:'files/patient_id', component: FilesComponent},
      { path: '**', redirectTo: 'home' }
    ]
  },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
