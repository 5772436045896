import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-new-report',
  templateUrl: './modal-new-report.component.html',
  styleUrls: ['./modal-new-report.component.scss']
})
export class ModalNewReportComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalNewReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
    console.log(this.data)
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

}
