export default {
  SAMPLE_SERVER_BASE_URL: 'https://mindhelp.mx',
  API_KEY: '46356992',
  SESSION_ID: '2_MX40NjM1Njk5Mn5-MTU4MzM2NTc4OTQyMn52WElLYzR0dGVYNjRTY3FMKzlnY1dhYTF-UH4',
  TOKEN: 'T1==cGFydG5lcl9pZD00NjM1Njk5MiZzaWc9OGZiODg2YmNlZTcwYWNhNjUwMTI0N2UzZmQ1NTBlZGFhZmJiN2ZjZTpzZXNzaW9uX2lkPTJfTVg0ME5qTTFOams1TW41LU1UVTRNek0yTlRjNE9UUXlNbjUyV0VsTFl6UjBkR1ZZTmpSVFkzRk1LemxuWTFkaFlURi1VSDQmY3JlYXRlX3RpbWU9MTU4MzM2NTc4OSZyb2xlPXB1Ymxpc2hlciZub25jZT0xNTgzMzY1Nzg5LjQyMDEyMTM4OTk5NDU4JmV4cGlyZV90aW1lPTE1ODM5NzA1ODkmY29ubmVjdGlvbl9kYXRhPW5hbWUlM0RBcHBvaW50bWVudC0xJmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9'
};

// export default {
//   SAMPLE_SERVER_BASE_URL: 'https://mindhelp.mx',
//   API_KEY: '46356992',
//   SESSION_ID: '2_MX40NjM1Njk5Mn5-MTU4MzAzNzM5MTg3NH44YWw3TFA3THZUSWU3L3lZUW1YK0dlaGZ-UH4',
//   TOKEN: 'T1==cGFydG5lcl9pZD00NjM1Njk5MiZzaWc9OTA3N2FkYzQyZjkzZTk5MTA4NjU4NTgwM2M4Mzg2ZjlmZjlmOGZlYjpzZXNzaW9uX2lkPTJfTVg0ME5qTTFOams1TW41LU1UVTRNekF6TnpNNU1UZzNOSDQ0WVd3M1RGQTNUSFpVU1dVM0wzbFpVVzFZSzBkbGFHWi1VSDQmY3JlYXRlX3RpbWU9MTU4MzAzNzM5MSZyb2xlPXB1Ymxpc2hlciZub25jZT0xNTgzMDM3MzkxLjg3MjgxOTYxNzUzMzE0JmV4cGlyZV90aW1lPTE1ODM2NDIxOTEmY29ubmVjdGlvbl9kYXRhPW5hbWUlM0RBcHBvaW50bWVudC0xJmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9'
// };
