import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { SetUserAction, DeactivateUserAction } from '../redux/user.actions';
import { Router } from '@angular/router';
import { SetCategorieAction } from '../redux/categories.actions';
import { GetChatHistory } from '../redux/chat-history.actions';
import { ActivateLoadingAction, DeactivateLoadingAction } from '../redux/ui.actions';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private publicKey = "";
  private secureIV = "";
  private privateKey = "";
  token = "";
  // URL Produccion
  public ipServer = "https://api.mindhelp.mx/";
  //public devUrl = "https://mindhelpapi.beesoftware.dev/api/v1/";

  // URL Developer
  //private ipServer = "https://api.mindhelp.dev/";
  // URL Local
  //private ipServer = "http://192.168.100.7:1337/";
  private newUrl = this.ipServer + "api/v1/";
  //private newUrl = "https://mindhelpapi.beesoftware.dev/api/v1/";

  public user = null;

  public isEspecialista = false;

  constructor(private http: HttpClient, private store: Store<AppState>, private route: Router) { }

  login(user) {
    let url = `${this.newUrl}auth/local/signin`;

    this.store.dispatch(new ActivateLoadingAction());

    let encUser = { message: this.encrypt(user) };

    this.http.post(url, encUser).subscribe((data: any) => {
      let decUser = JSON.parse(this.decrypt(data.message));
      let user = decUser.data.items[0];
      this.store.dispatch(new SetUserAction({ ...user }))
      this.user = user;
      this.privateKey = user.encKey;
      this.token = user.token;
      this.store.dispatch(new DeactivateLoadingAction());
      this.getProfile(this.user.id).subscribe((d: any) => {
        let decData = JSON.parse(this.decrypt(d.message, 'private'));
        let profile = decData.data.items[0];
        profile.services.forEach(ser => {
          if (ser.category_id == 8)
            this.isEspecialista = true;
        });
      });
      this.route.navigate(['panel']);

    }, err => {
      this.store.dispatch(new DeactivateLoadingAction());
      let decData = JSON.parse(this.decrypt(err.error.message));
      console.log(decData);
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: decData.error.errors[0].message,
      });
    })
  }

  logout() {
    this.store.dispatch(new DeactivateUserAction());
    this.user = null;
    this.route.navigate(['/login']);
  }

  register(user) {

    let url = `${this.newUrl}auth/local/signup/doctor`;

    let encUser = { message: this.encrypt(user) };

    this.http.post(url, encUser).subscribe((data: any) => {
      console.log(data);
      let decUser = JSON.parse(this.decrypt(data.message));
      let user = decUser.data.items[0];

      this.store.dispatch(new SetUserAction({ ...user }))
      this.user = user;
      this.privateKey = user.encKey;
      this.token = user.token;
      this.route.navigate(['panel']);

      Swal.fire({
        icon: 'success',
        title: 'Felicidades',
        text: "Se ha registrado con exito",
      });
    }, err => {
      let decData = JSON.parse(this.decrypt(err.error.message));
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: decData.error.errors[0].message,
      });
    })
  }

  isAuth(): boolean {
    console.log(this.user)
    if (this.user != null) {
      return true;
    } else {
      this.route.navigate(['login']);
      return false;
    }
  }

  /**
   *
   *
   * @param {*} data la informacion que encriptaras
   * @param {string} [type="public"] si la llave que usaras sera publica o privada
   * @returns retorna la data encryptada
   * @memberof AuthService
   */
  encrypt(data: any, type: string = "public") {
    let key = (type == "public") ? "OnnptHpzXXyREqAbmRYx0RCgp1vMRoHA" : this.privateKey;
    this.getKey(key);

    let _key = CryptoJS.enc.Utf8.parse(this.publicKey);
    let _iv = CryptoJS.enc.Utf8.parse(this.secureIV);

    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data), _key, {
      keySize: 128,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  /**
   *
   *
   * @param {*} data la informacion que desencriptaras
   * @param {string} [type="public"] si la llave que usaras sera publica o privada
   * @returns retorna la data desencryptada
   * @memberof AuthService
   */
  decrypt(data: any, type: string = "public") {
    let key = (type == "public") ? "OnnptHpzXXyREqAbmRYx0RCgp1vMRoHA" : this.privateKey;
    this.getKey(key);

    let _key = CryptoJS.enc.Utf8.parse(this.publicKey);
    let _iv = CryptoJS.enc.Utf8.parse(this.secureIV);

    let decrypted = CryptoJS.AES.decrypt(
      data, _key, {
      keySize: 128,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8)
    return decrypted;
  }

  getKey(key) {
    this.secureIV = key.substr(0, 16);
    this.publicKey = key;
  }

  getSpecialities() {
    let url = `${this.newUrl}system/categories`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers })
  }

  //  funtion to get the dates
  datesToConfirm(id) {

    let url = `${this.newUrl}users/${id}/appointments/status/to-confirm`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  datesFinish(id) {

    let url = `${this.newUrl}users/${this.user.id}/appointments/${id}/status/finished`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.post(url, {}, { headers });
  }

  endDatesFinish(limit = 10, skip = 0) {

    let url = `${this.newUrl}users/${this.user.id}/appointments/status/finished`
      + (limit > 0 ? `?limit=${limit}` : ``) + (skip > 0 ? `&skip=${skip}` : ``);
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });

  }

  datesScheduled(id) {
    let url = `${this.newUrl}users/${id}/appointments/status/scheduled`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });

  }

  datesInProgress(id) {
    let url = `${this.newUrl}users/${id}/appointments/status/in-progress`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });

  }
  // finish

  // change date status
  acceptDate(userId, dateId) {
    let url = `${this.newUrl}users/${userId}/appointments/${dateId}/status/accepted`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.encrypt({}, "private") };

    return this.http.post(url, encData, { headers });
  }

  rejectDate(userId, dateId) {
    let url = `${this.newUrl}users/${userId}/appointments/${dateId}/status/rejected`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.encrypt({}, "private") };

    return this.http.post(url, encData, { headers });
  }

  cancelDate(userId, dateId) {
    let url = `${this.newUrl}users/${userId}/appointments/${dateId}/status/cancelled`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.post(url, {}, { headers });
  }

  conectDate(userId, dateId) {
    let url = `${this.newUrl}users/${userId}/appointments/${dateId}/status/connected`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.post(url, {}, { headers });
  }

  finishDate(id) {

    let url = `${this.newUrl}users/${this.user.id}/appointments/${id}/status/finished`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.post(url, {}, { headers });

  }

  // finish

  getHours(id, time, view) {
    let url = `${this.newUrl}users/${id}/time-slots`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });

    const params = new HttpParams().set('time_as_reference', time).set('time_type', view);

    return this.http.get(url, { headers, params });
  }

  getCalendarDays(id, time, view) {
    let url = `${this.newUrl}ecomm/users/find-availability-by-doctor`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });

    const params = new HttpParams().set('time_as_reference', time)
      .set('time_type', 'month')
      .set('time_slot_type', 'hasAppointments')
      .set('userId', id);

    return this.http.get(url, { headers, params });
  }

  getTimeDetails() {
    let url = `${this.newUrl}users/${this.user.id}/time-slots/week`;

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });

    return this.http.get(url, { headers });
  }

  getBookingDetails(id) {
    let url = `${this.newUrl}appointments/${id}`;

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });

    return this.http.get(url, { headers });
  }

  getOutOfOfficeDetails(id) {
    let url = `${this.newUrl}users/calendar/out-of-office/${id}`;

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });

    return this.http.get(url, { headers });
  }

  setDocHours(id, data) {
    let url = `${this.newUrl}users/${id}/time-slots`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.encrypt(data, "private") };

    return this.http.post(url, encData, { headers });
  }

  deleteTimeSlot(userId, id) {
    let url = `${this.newUrl}users/${userId}/time-slots/${id}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.delete(url, { headers });
  }

  // Out Of Office
  getOutOffice() {
    let url = `${this.newUrl}users/${this.user.id}/calendar/out-of-office`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  setOutOffice(data) {
    let url = `${this.newUrl}users/${this.user.id}/calendar/out-of-office`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.encrypt(data, "private") };

    //return this.http.post(url, encData , { headers });
    return this.http.post(url, data, { headers });
  }

  deleteOutOffice(id) {
    let url = `${this.newUrl}users/${this.user.id}/calendar/out-of-office/${id}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.delete(url, { headers });
  }

  // Fin Out Of Office

  getProfile(id) {
    let url = `${this.newUrl}users/${id}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  editProfile(data, id) {
    let url = `${this.newUrl}users/${id}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
      'Content-Type': 'application/json'
    });

    let encData = { message: this.encrypt(data, "private") };

    return this.http.patch(url, encData, { headers });
  }

  setServices(idUser, id) {

    let url = `${this.newUrl}users/${idUser}/services/${id}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.post(url, {}, { headers });
  }

  deleteService(idUser, id) {
    let url = `${this.newUrl}users/${idUser}/services/${id}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.delete(url, { headers });
  }

  uploadCv(data, id) {
    let url = `${this.newUrl}users/${id}/resume`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let encData = { message: this.encrypt(data, "private") };

    return this.http.patch(url, encData, { headers });
  }

  uploadSing(data) {
    let url = `${this.newUrl}users/${this.user.id}/sign`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let encData = { message: this.encrypt(data, "private") };

    return this.http.patch(url, encData, { headers });
  }

  rePassRequest(data) {
    let url = `${this.newUrl}auth/reset-password/request`;
    return this.http.post(url, data);
  }

  rePassword(data) {
    let url = `${this.newUrl}auth/reset-password`;

    return this.http.post(url, data);
  }

  getChats(id) {
    let url = `${this.newUrl}users/${id}/chats`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers });
  }

  getChatHistory(userId, chatId) {
    let url = `${this.newUrl}users/${userId}/chats/${chatId}/messages`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    this.http.get(url, { headers }).subscribe((d: any) => {

      let decData = JSON.parse(this.decrypt(d.message, 'private'));

      this.store.dispatch(new GetChatHistory([...decData.data.items]))
    }, err => {
      console.log(err)
    });
  }

  sendMessage(userId, chatId, data) {
    let url = `${this.newUrl}users/${userId}/chats/${chatId}/messages`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.post(url, data, { headers })
  }

  getTimeZones() {
    // paRA PROD
    return this.http.get("https://worldtimeapi.org/api/timezone");
    // LOCALHOST
    //return this.http.get("http://worldtimeapi.org/api/timezone");
  }

  getImgId(id) {
    let url = `${this.newUrl}system/media/${id}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(url, { headers })
  }

  contactUs(data) {
    let url = `${this.newUrl}system/emails/send-contact-form`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });

    let encData = { message: this.encrypt(data, "private") };


    this.http.post(url, encData, { headers }).subscribe((d: any) => {
      Swal.fire({
        icon: 'success',
        title: 'Mensaje enviado',
        text: "En un momento nos comunicaremos con usted",
      });
    }, err => {
      let decData = JSON.parse(this.decrypt(err.error.message, "private"));

      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: decData.error.errors[0].message,
      });
    });
  }

  sinImagen() {
    return this.ipServer + "sinimagen.png";
  }

  deleteRecipe(data) {
    let url = `${this.newUrl}users/${data.folio}/recipes/`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.delete(url, { headers });
  }

  getRecipes(patient_id) {
    //let url = `${this.newUrl}users/${this.user.id}/recipes/false`;
    //let url = `${this.newUrl}users/${user_id}/recipes/${this.user.id}`;
    let url = `${this.newUrl}users/recipes?where={"doctor_id":${this.user.id}}`;
    if (patient_id)
      url = `${this.newUrl}users/recipes?where={"doctor_id":${this.user.id},"patient_id":${patient_id}}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    /*let params = new HttpParams();
    Object.keys(obj).forEach(key => {
      if (obj[key] !== '' && obj[key] !== undefined) {
        params = params.set(key, obj[key]);
      }
    });*/
    return this.http.get(url, { headers });//params:params
  }

  setRecipe(data) {
    let url = `${this.newUrl}users/${this.user.id}/recipes`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.encrypt(data, "private") };

    return this.http.post(url, encData, { headers });
  }

  getFileUsers() {
    let url = `${this.newUrl}users/find-users`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get(url, { headers });
  }

  getDiagnostics(where) {
    let url = `${this.newUrl}users/diagnostics`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    where.doctor_user_id = this.user.id;
    const params = new HttpParams().set("where", JSON.stringify(where));

    //if(where) params.set("where", JSON.stringify(where));
    //if(patient_user_id) params.set("patient_user_id", doctor_user_id);
    //if(service_id) params.set("service_id", doctor_user_id);

    return this.http.get(url, { headers, params });
  }

  setDiagnostic(data) {
    let url = `${this.newUrl}users/diagnostics`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.encrypt(data, "private") };

    return this.http.post(url, encData, { headers });
  }

  deleteDiagnostic(data) {
    let url = `${this.newUrl}users/diagnostics/${data.id}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.delete(url, { headers });
  }

  getInsured() {
    let url = `${this.newUrl}users?where={"subrole_id":1}&limit=1000`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get(url, { headers });
  }

  getDate(id) {
    let url = `${this.newUrl}appointments/${id}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get(url, { headers });
  }

  moveBooking(id, data) {
    let url = `${this.newUrl}appointments/${id}/move-doctor`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let encData = { message: this.encrypt(data, "private") };

    return this.http.post(url, encData, { headers });
  }

  getDoctorTimeList(id, time) {
    let url = `${this.newUrl}users/${id}/time-slots`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
    });

    const params = new HttpParams().set('time_as_reference', time);

    return this.http.get(url, { headers, params });
  }

  // getDoctor 
  getDoctorDetail(id, cat_id, month) {
    // let url = `${this.newUrl}users/${id}`;
    let startAt = (moment(month).startOf('month').format('YYYY-MM-DD HH:mm:ss'));
    let endAt = (moment(month).endOf('month').format('YYYY-MM-DD HH:mm:ss'));
    let url = `${this.newUrl}ecomm/users/${cat_id}/time-slot-by-day?startDate=${startAt}&endDate=${endAt}&userId=${id}`;

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get(url, { headers });
  }
  // reportes
  // generar reporte
  requestReport(id, date) {
    let url = `${this.newUrl}ecomm/users/create-bill-request?userId=${id}&dateAsReference=${date}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let encData = { message: this.encrypt({}, "private") };

    return this.http.post(url, encData, { headers });
  }
  // traer los reportes
  getReports(id) {

    let url = `${this.newUrl}ecomm/users/get-all-reports-by-doctor?userId=${id}`;

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get(url, { headers });
  }

  sendBill(reportId, file) {
    let url = `${this.newUrl}ecomm/users/upload-bill-report-media?appointmentToBillReportId=${reportId}&type=bill`;
    const formData = new FormData();
    formData.append('media_source', file);
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    //let encData = { message: this.encrypt(formData, "private") };
    return this.http.put(url, formData, { headers });
  }

}
