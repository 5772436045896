import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private url = "https://api.mindhelp.mx/api/v1/";
  // private url = "https://api.mindhelp.dev/api/v1/";

  constructor(private http: HttpClient, private store: Store<AppState>, private route: Router, private serviceAuth: AuthService) { }


  addTokenOS(id,data){
    let url = `${this.url}users/${id}/tokens/notification`;

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.serviceAuth.token
    });

    return this.http.post(url, data, {headers});
  }


}
