import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { AuthService } from '../services/auth.service';
import { ModalReportePagadoComponent } from './modal-reporte-pagado/modal-reporte-pagado.component';
import * as moment from 'moment';


@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.scss']
})
export class ViewReportComponent implements OnInit {

  doctorId: any = "";
  reports = [];
  reportsCopy = "";
  currentReport = null;

  constructor(public dialog: MatDialog, private service: AuthService, private store: Store<AppState>) {
    // ***************************************
    // ************Descomentar****************
    // ***************************************
    this.doctorId = this.service.user.id;
    // ***************************************
    // **************Eliminar*****************
    // ***************************************
    // this.doctorId = 1;
    let doctor = this.service.user;
    console.log(doctor)
  }

  ngOnInit() {
    this.getReports();
  }
  // regrese el estatus en texto
  formatStatus(status): string {
    switch (status) {
      case 'pending':
        return 'En revisión'
      case 'validated':
        return 'Aprobado';
      case 'paid':
        return 'Pagado';
      case 'rejected':
        return 'Rechazado';
      default:
        return '';
    }
  }

  formatDate(date): string {
    let startAt = (moment(Number(date)).startOf('month').format('DD MMM YYYY'));
    let endAt = (moment(Number(date)).endOf('month').format('DD MMM YYYY'));
    return `${startAt} - ${endAt}`
  }

  modalreportePagado(report): void {
    const dialogRef = this.dialog.open(ModalReportePagadoComponent, {
      width: '600px',
      height: '612px',
      data: report
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getReports();
      }
    });
  }

  filter(status) {
    let copy = JSON.parse(this.reportsCopy);
    if (status == 'all') {
      this.reports = [...copy];
    } else {
      this.reports = copy.filter(elem => elem.status == status);
    }
  }

  getReports() {
    this.service.getReports(this.doctorId).subscribe((data: any) => {
      let decData = JSON.parse(this.service.decrypt(data.message, "private"));
      console.log(decData.data.items)
      this.reports = decData.data.items.filter(elem => elem.status != 'invoice_attached');
      this.reportsCopy = JSON.stringify(this.reports);
      console.log(this.reports)
    }, err => {
      console.log(err)
    })
  }

  getFile(type) {
    console.log(this.currentReport)
    if(!this.currentReport) return;
    let r = this.currentReport;
    switch (type) {
      case 'vaucher':
        if(r.voucherMedia) window.open(r.voucherMedia.url);
        break;
      case 'bill':
        if(r.billMedia) window.open(r.billMedia.url);
        break;
    }
  }

}
