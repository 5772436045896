import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material';
import { AuthService } from '../services/auth.service';
import { MatDialog, } from '@angular/material';
import * as momentTz from 'moment-timezone'
import { ModalNewReportComponent } from './modal-new-report/modal-new-report.component';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { ActivateLoadingAction, DeactivateLoadingAction } from '../redux/ui.actions';
import Swal from 'sweetalert2';
import * as moment from 'moment';


@Component({
  selector: 'app-new-report',
  templateUrl: './new-report.component.html',
  styleUrls: ['./new-report.component.scss']
})
export class NewReportComponent implements OnInit, AfterViewInit {

  // calendar
  selectedDate = moment();

  @ViewChild('calendar', { static: true })
  calendar: MatCalendar<moment.Moment>;
  isDatePicker = false;
  minDate = new Date();

  doctorDate = "";
  doctorTimeSlots = [];
  doctorId: any = "";
  doctor;
  timeLabel = "Seleccione una hora";
  Today;
  actualMonth;
  horasTotales = 0;
  horasAgendadas = 0;
  horasTRabajadas = 0;
  total = 0;
  startAt;
  endAt;
  currentMonth = moment().format('MM/DD/YYYY');
  calendarDays = [];
  canSendReport = false;


  constructor(private renderer: Renderer2,
    private service: AuthService,
    public dialog: MatDialog,
    private store: Store<AppState>) {
 
    this.doctorId = this.service.user.id;
    
    this.doctor = this.service.user;
    this.actualMonth = (moment().format('MMMM YYYY'));
    this.startAt = (moment().startOf('year').format());
    this.endAt = (moment().endOf('month').format());
    this.getDoctorDetail(this.doctorId);
    this.getTimeSlots();
  }

  ngOnInit() {
    moment.locale('es');
  }

  monthChanged(date: moment.Moment) {
  }

  dateChanged() {
    this.isDatePicker = true;
    this.getDate(this.selectedDate);
  }

  getDate(date) {

    this.doctorDate = moment(date).format('MM/DD/YYYY');

    this.service.getDoctorTimeList(this.doctorId, moment(date).valueOf()).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message));

      let slots = this.formatSlots(decData.data.items);
      let GMT = moment().format('ZZ');

      console.log(slots);
      let newSlots = []
      slots.forEach(slot => {
        // console.log(moment(slot.start).format('LLL'));
        var dateIni = moment(slot.start);
        var dateFin = moment().add(30, 'minutes');

        if (moment(slot.start).format('DD-MM-YYYY') == moment(date).format('DD-MM-YYYY'))
          if (slot.slot_type == "appointment")
            newSlots.push({ start: moment(slot.start).format('HH:mm'), start_hour: moment(slot.start).format('h:mm a'), slot_type: slot.slot_type });
      });

      // console.log(newSlots);
      this.doctorTimeSlots = newSlots;

      this.timeLabel = "Seleccione una hora";
      if (this.doctorTimeSlots.length == 0) {
        this.timeLabel = "No hay horas disponibles";
      } else if (this.Today != this.doctorDate) {

      }

    }, err => {
      console.log(err)
    });

  }

  formatSlots(slots) {
    let newTimesS = [];
    slots.forEach(element => {
      // get the day of the mont
      let date = moment.utc(element.day).format('MMM/DD/YYYY');

      // get the times in the day of the mont
      let newTimes = element.availability.map(elem => {

        let start = moment(elem.start_at_miliseconds_utc).format();
        let plushour = moment(elem.start_at_miliseconds_utc).add(1, 'hour').format();

        let end = moment(plushour).format()

        return {
          start: new Date(start),
          minutes: elem.minutes,
          slot_type: elem.slot_type
        }
      })

      newTimesS = newTimesS.concat(newTimes)

    });
    return newTimesS
  }

  openDialog(): void {
    let data = {
      hrsTrabajadas: this.horasTRabajadas,
      total: this.total,
      id: this.doctorId,
      month: moment(this.currentMonth).format('MMMM YYYY'),
      name: this.doctor.first_name
    }
    const dialogRef = this.dialog.open(ModalNewReportComponent, {
      width: '600px',
      height: '612px',
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let date = moment(this.currentMonth).format('YYYY-MM-DD HH:mm:ss');
        this.service.requestReport(this.doctorId, date).subscribe((data: any) => {
          let decData = JSON.parse(this.service.decrypt(data.message));
          console.log(decData);
          Swal.fire({
            icon: 'success',
            title: 'SOLICITUD ENVIADA CON ÉXITO',
            text: "",
          });
        }, err => {
          console.log(err)
        });
      }
    });
  }

  getDoctorDetail(doc_id) {
    this.store.dispatch(new ActivateLoadingAction());
    console.log(doc_id)
    this.service.getDoctorDetail(doc_id, 0, this.currentMonth).subscribe((data: any) => {
      let decData = JSON.parse(this.service.decrypt(data.message,"public"));
      // this.doctor = decData.data.items[0]
      if (decData.data.items.length == 0) return;
      let times = decData.data.items[0].time_slots;
      console.log("doctor")
      console.log(decData.data.items)

      // datos de horas
      this.horasTotales = times.length;
      let horasAgendadas = times.filter(elem => elem.slot_type == "appointment");
      console.log(horasAgendadas)
      this.horasAgendadas = horasAgendadas.length;
      let horasTrabajadas = horasAgendadas.filter(elem => elem.payload.status == "appointment_finished");
      this.horasTRabajadas = horasTrabajadas.length;
      let total = horasTrabajadas.reduce((a, b) => a + (b['payload'].base_price || 0), 0);
      this.total = total;
      // Validar boton
      let endMonth = true;//moment(this.currentMonth).endOf('month').format('x') <= moment().format('x');
      let reportpayed = horasAgendadas.filter(elem => elem.payload.paymentStatus == "pay_in_progress").length > 0;
      this.canSendReport = ((total > 0 ? true : false) && endMonth && !reportpayed);
      this.store.dispatch(new DeactivateLoadingAction());
    }, err => {
      console.log(err)
      // this.presentAlert(err.error.msg);
      this.store.dispatch(new DeactivateLoadingAction());
    })
  }

  ngAfterViewInit(): void {
    this.highlightDays(this.calendarDays);
  }

  ngDoCheck(): void {
    let c: any = this.calendar;
    let currentMonth = moment(c._clampedActiveDate).format('MM/DD/YYYY');
    if (this.currentMonth != currentMonth) {
      console.log(currentMonth, this.currentMonth);
      this.isDatePicker = false;
      this.actualMonth = (moment(currentMonth).format('MMMM YYYY'));
      this.currentMonth = currentMonth;
      this.getDoctorDetail(this.doctorId);
      this.getTimeSlots();
      this.highlightDays(this.calendarDays);
    }
  }

  // cambiar el user id despues
  getTimeSlots() {
    this.store.dispatch(new ActivateLoadingAction());

    let day = moment.utc(this.currentMonth).format('x');
    let id = 1;
    // let id = this.doctorId;

    this.service.getCalendarDays(this.doctorId, day, 'month').subscribe((data: any) => {
      console.log(data)
      let decUser = JSON.parse(this.service.decrypt(data.message, "public"));
      console.log(decUser.data.items)
      let newDays = decUser.data.items.map(elem => {
        return moment.utc(elem.day).format('MM/DD/YYYY');
      })
      console.log(newDays);
      this.calendarDays = newDays;
      this.highlightDays(this.calendarDays);
      this.store.dispatch(new DeactivateLoadingAction());
    }, err => {
      this.store.dispatch(new DeactivateLoadingAction());
    })
  }

  private highlightDays(days: string[]) {
    const dayElements = document.querySelectorAll(
      'mat-calendar .mat-calendar-table .mat-calendar-body-cell'
    );
    // console.log("Calendario")
    Array.from(dayElements).forEach((element) => {
      let day = moment(element.getAttribute('aria-label')).format('MM/DD/YYYY');
      const matchingDay = days.find((d) => d === day) !== undefined;

      if (matchingDay) {
        this.renderer.addClass(element, 'available');
        this.renderer.setAttribute(element, 'title', 'Event 1');
      } else {
        this.renderer.removeClass(element, 'available');
        this.renderer.removeAttribute(element, 'title');
      }
    });
  }

}
