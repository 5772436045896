import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-modal-reporte-pagado',
  templateUrl: './modal-reporte-pagado.component.html',
  styleUrls: ['./modal-reporte-pagado.component.scss']
})
export class ModalReportePagadoComponent implements OnInit {

  uploadFile;
  isUpload = false;
  file;

  constructor(
    public dialogRef: MatDialogRef<ModalReportePagadoComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private service: AuthService) { }

  ngOnInit() {
    moment.locale('es');
    console.log(this.data);
  }

  // regrese el estatus en texto
  formatStatus(status): string {
    switch (status) {
      case 'pending':
        return 'Reporte en revisión'
      case 'validated':
        return 'Reporte en aprobado';
      case 'paid':
        return 'Reporte pagado';
      case 'rejected':
        return 'Reporte en rechazado';
      default:
        return '';
    }
  }

  formatDate(date): string{
    return moment(Number(date)).format('MMMM YYYY')
  }

  formatEndDate(date){
    return moment(Number(date)).endOf('month').format('DD MMM YYYY');
  }

  getFile(event){
    console.log("Aechivo cargado")
    this.file = event.target.files[0];
    console.log(this.file)
    this.isUpload= true;
  }

  fileOnloadCv(e) {
    let result = e.target.result;
    this.uploadFile = {
      base64: result.split(",")[1],
      base64_extension: "pdf"
    }
  }

  save(){
    console.log(this.uploadFile)
    this.service.sendBill(this.data.id,this.file).subscribe( d=>{
      Swal.fire({
        icon: 'success',
        title: 'FACTURA ENVIADA CON ÉXITO',
      });
      this.dialogRef.close(true);

    },err=>{
      console.log(err)
    } )
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  getPDF(){
    if(this.data.voucherMedia) window.open(this.data.voucherMedia.url)
  }

}
