import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  userImg= "";

  user = { 
    id: 0,
    first_name: "",
    last_name: "",
    photo_media_id:{ url: ""},
    email: "",
    email_verified_at: "",
    role_id: 0,
    profile_image: "",
    country: "",
    state: "",
    city: "",
    st_address: "",
    zip: "",
    country_code: "",
    phone_0: "",
    date_of_birth: "",
    gender: "",
    device_token: "",
    fcm_id: "",
    push_notification: "",
    time_zone: "",
    account_status: 0,
    account_delete: 0,
    registration_step: 0,
    session_id: "",
    token: "",
    status: 0,
    deleted_at: "",
    created_at: "",
    updated_at: "",
    country_code_flag: "",
    total_social: 0,
    profile_image_url: "",
    created_atz: "",
    enckey: "",
    doctor: "",
  };

  title = "Editar Perfil";

  constructor( private service: AuthService ) { 
    this.user = this.service.user;

    this.userImg = this.user.photo_media_id ? this.user.photo_media_id.url : "https://via.placeholder.com/300.png/09f/fff" ;
  }

  ngOnInit() {
  }

  changeTitle(title){
    this.title = title;
  }

}
